import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AuthRedirectService {
    shouldRedirect: boolean = true;

    public redirectToLogin(): void {
        if (this.shouldRedirect) {
            const dashboardUrl = `${location.origin}`;
            location.href = `${environment.loginGwoUrl}?redirect=${encodeURIComponent(dashboardUrl)}`;
        }
    }

    public redirectToLogOut(): void {
        if (this.shouldRedirect) {
            location.href = environment.logOutGwoUrl;
        }
    }

    public openExternalPage(link: string): Window | null {
        return window.open(link,'_blank');
    }
}
